import React, { useEffect, useRef, useState } from "react";
import { Network } from "vis-network";
import { DataSet } from "vis-data";
import axios from "axios";
import 'vis-network/styles/vis-network.min.css';
import './style.css';
import { useKeycloak } from "@react-keycloak/web";
import { Link, useParams } from "react-router-dom";
import { getHostNameServer } from "../../../services/helpers/config";
import { Button, Modal } from "react-bootstrap";
import ConsoleTerminal from "../../public/terminal/terminal";

const ShowProjects = () => {

    let network = null;

    const clientHeight = document.documentElement.clientHeight - 70;
    /** const clientWidth = document.documentElement.clientWidth - 270; */

    const { keycloak, initialized } = useKeycloak(); // user keycloak login generate
    const container = useRef();
    let { id, name } = useParams();

    const [data, setData] = useState({ username: '', password: '', host: '', port: '' });
    const [project, setProject] = useState([]);
    const [running, setRunning] = useState(false);
    const [show, setShow] = useState(false); // var status to modal
    const handleClose = () => setShow(false); // close modal
    const handleShow = () => setShow(true); // show modal

    useEffect(() => {
        console.log(initialized);
        getProject();
    }, []);

    let options = { // options to network
        locale: 'en', // language to reading
        locales: {
            en: {
                edit: 'Edit',
                del: 'Delete selected',
                back: 'Back',
                addNode: 'Add device',
                addEdge: 'Add link',
                editNode: 'Edit device',
                editEdge: 'Edit link',
                addDescription: 'Click in an empty space to place a new node.',
                edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
                editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
                createEdgeError: 'Cannot link edges to a cluster.',
                deleteClusterError: 'Clusters cannot be deleted.',
                editClusterError: 'Clusters cannot be edited.'
            }
        },
        configure: {
            enabled: false, // hability interaction graphic panel
            filter: 'nodes,edges',
            showButton: false
        },
        nodes: {
            borderWidth: 1,
            borderWidthSelected: 2,
            //brokenImage: undefined,
            chosen: true,
            color: {
                border: '#2B7CE9',
                background: '#97C2FC',
                highlight: {
                    border: '#2B7CE9',
                    background: '#D2E5FF'
                },
                hover: {
                    border: '#2B7CE9',
                    background: '#D2E5FF'
                }
            },
            opacity: 1,
            fixed: {
                x: false, // allow move nodes to horizontal
                y: false // allow move nodes to vertical
            },
            font: { // propiedades de texto del nodo
                color: '#343434',
                size: 10, // px
                face: 'arial',
                background: 'none',
                strokeWidth: 0, // px
                strokeColor: '#ffffff',
                align: 'center',
                multi: false,
                vadjust: 0,
                bold: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold'
                },
                ital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'italic',
                },
                boldital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold italic'
                },
                mono: {
                    color: '#343434',
                    size: 15, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                }
            },
            group: undefined,
            heightConstraint: true,
            hidden: false,
            /*icon: {
                face: 'FontAwesome',
                code: undefined,
                weight: undefined,
                size: 50,  //50,
                color: '#2B7CE9'
            },
            image: undefined,*/
            imagePadding: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0
            },
            label: undefined,
            labelHighlightBold: true,
            level: undefined,
            mass: 2,
            physics: true,
            scaling: {
                min: 10,
                max: 30,
                label: {
                    enabled: false,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                },
                customScalingFunction: function (min, max, total, value) {
                    if (max === min) {
                        return 0.5;
                    }
                    else {
                        let scale = 1 / (max - min);
                        return Math.max(0, (value - min) * scale);
                    }
                }
            },
            shadow: {
                enabled: false,
                color: 'rgba(0,0,0,0.5)',
                size: 10,
                x: 5,
                y: 5
            },
            shape: 'ellipse',
            shapeProperties: {
                borderDashes: false, // only for borders
                borderRadius: 6,     // only for box shape
                interpolation: true,  // only for image and circularImage shapes
                useImageSize: false,  // only for image and circularImage shapes
                useBorderWithImage: false,  // only for image shape
                coordinateOrigin: 'center'  // only for image and circularImage shapes
            },
            size: 10,
            title: undefined,
            value: undefined,
            widthConstraint: true,
            //x: undefined,
            //y: undefined
        },
        edges: {
            arrows: { // Direction arrows
                to: {
                    enabled: true,
                    //imageHeight: undefined,
                    //imageWidth: undefined,
                    scaleFactor: 1,
                    //src: undefined,
                    type: "arrow"
                },
                middle: {
                    enabled: true,
                    imageHeight: 10,
                    imageWidth: 10,
                    scaleFactor: 1,
                    src: "https://visjs.org/images/visjs_logo.png",
                    type: "image"
                },
                from: {
                    enabled: true,
                    //imageHeight: undefined,
                    //imageWidth: undefined,
                    scaleFactor: 1,
                    //src: undefined,
                    type: "arrow"
                }
            },
            endPointOffset: {
                from: 0,
                to: 0
            },
            arrowStrikethrough: true,
            chosen: true,
            color: {
                color: '#848484',
                highlight: '#848484',
                hover: '#848484',
                inherit: 'from',
                opacity: 1.0
            },
            dashes: true, // arrow line/intermitent
            font: {
                color: '#343434',
                size: 14, // px
                face: 'arial',
                background: 'none',
                strokeWidth: 2, // px
                strokeColor: '#ffffff',
                align: 'horizontal',
                multi: true,
                vadjust: 0,
                bold: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold'
                },
                ital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'italic',
                },
                boldital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold italic'
                },
                mono: {
                    color: '#343434',
                    size: 15, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                }
            },
            hidden: false, // hidden/show the arrows
            hoverWidth: 1.5,
            label: undefined,
            labelHighlightBold: true,
            length: undefined,
            physics: true,
            scaling: {
                min: 1,
                max: 15,
                label: {
                    enabled: true,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                },
                customScalingFunction: function (min, max, total, value) {
                    if (max === min) {
                        return 0.5;
                    }
                    else {
                        let scale = 1 / (max - min);
                        return Math.max(0, (value - min) * scale);
                    }
                }
            },
            selectionWidth: 1,
            //selfReferenceSize: 20,
            selfReference: {
                size: 20,
                angle: Math.PI / 4,
                renderBehindTheNode: true
            },
            shadow: {
                enabled: false,
                color: 'rgba(0,0,0,0.5)',
                size: 10,
                x: 5,
                y: 5
            },
            smooth: {
                enabled: false,
                type: "dynamic",
                roundness: 0.5
            },
            title: undefined,
            value: undefined,
            width: 0.8, // width the links
            widthConstraint: false
        },
        layout: {
            randomSeed: undefined,
            improvedLayout: true,
            clusterThreshold: 150,
            hierarchical: {
                enabled: false,
                levelSeparation: 150,
                nodeSpacing: 100,
                treeSpacing: 200,
                blockShifting: true,
                edgeMinimization: true,
                parentCentralization: true,
                direction: 'UD',        // UD, DU, LR, RL
                sortMethod: 'hubsize',  // hubsize, directed
                shakeTowards: 'leaves'  // roots, leaves
            }
        },
        interaction: { // Properties to interaction with nodes
            dragNodes: true,
            dragView: true,
            hideEdgesOnDrag: false,
            hideEdgesOnZoom: false,
            hideNodesOnDrag: false,
            hover: true,
            hoverConnectedEdges: true,
            keyboard: {
                enabled: true,
                speed: { x: 10, y: 10, zoom: 0.02 },
                bindToWindow: true,
                autoFocus: true,
            },
            multiselect: false,
            navigationButtons: true,
            selectable: true,
            selectConnectedEdges: true,
            tooltipDelay: 300,
            zoomSpeed: 1,
            zoomView: true
        },
        manipulation: {
            enabled: false,
            initiallyActive: true,
            addNode: false,
            editNode: (dataNode, callback) => {
                callback(dataNode);
            },
            deleteNode: false,
            addEdge: false,
            editEdge: false,
            deleteEdge: false,
            controlNodeStyle: {
                // all node options are valid.
                shape: 'dot',
                size: 6,
                color: {
                    background: '#ff0000',
                    border: '#3c3c3c',
                    highlight: {
                        background: '#07f968',
                        border: '#3c3c3c'
                    }
                },
                borderWidth: 2,
                borderWidthSelected: 2
            }
        },
        physics: {
            enabled: false, // enable/disable animation of nodes and edges
            barnesHut: {
                theta: 0.5,
                gravitationalConstant: -2000,
                centralGravity: 0.3,
                springLength: 95,
                springConstant: 0.04,
                damping: 0.09,
                avoidOverlap: 0
            },
            forceAtlas2Based: {
                theta: 0.5,
                gravitationalConstant: -50,
                centralGravity: 0.01,
                springConstant: 0.08,
                springLength: 100,
                damping: 0.4,
                avoidOverlap: 0
            },
            repulsion: {
                centralGravity: 0.2,
                springLength: 200,
                springConstant: 0.05,
                nodeDistance: 100,
                damping: 0.09
            },
            hierarchicalRepulsion: {
                centralGravity: 0.0,
                springLength: 100,
                springConstant: 0.01,
                nodeDistance: 120,
                damping: 0.09,
                avoidOverlap: 0
            },
            maxVelocity: 50,
            minVelocity: 0.1,
            solver: 'barnesHut',
            stabilization: {
                enabled: true,
                iterations: 1000,
                updateInterval: 100,
                onlyDynamicEdges: false,
                fit: true
            },
            timestep: 0.5,
            adaptiveTimestep: true,
            wind: { x: 0, y: 0 }
        }
    };

    let nodes = new DataSet(project.nodes); // array nodes for network project
    let edges = new DataSet(project.edges); // array edges for network project

    useEffect(() => {
        network = container.current && new Network(container.current, { nodes, edges }, options);
        // Use `network` here to configure events, etc
        network.on("stabilized", (params) => {
            console.log('estabilizado');
        });
        network.on("doubleClick", (params) => {
            console.log('Clicked', params);
            setData({
                username: 'undefined',
                password: 'undefined',
                host: '0.0.0.0',
                port: '22'
            });
            handleShow();
        });
        network.on("click", (params) => {
            console.log('Clicked', params);
        });
        network.on("oncontext", (params) => {
            console.log(params);
        });
        saveLocal();
    }, [container, nodes, edges]);

    /* Save local data network */
    const saveLocal = () => {
        const compose = {
            nodes: network.body.data.nodes.get(),
            edges: network.body.data.edges.get()
        };
        localStorage.removeItem("composeUpdate");
        localStorage.setItem('composeUpdate', JSON.stringify(compose));
        /**const saved = JSON.parse(localStorage.getItem('composeUpdate'));
        options.physics.enabled = false;
        network.setOptions(options);*/
    }

    // get project selected
    const getProject = async () => {
        await axios.get(`${getHostNameServer()}/projects/${id}/${name}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            setProject(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    // Run project
    const run = () => {
        setRunning(true);
    }

    // Stop project
    const stop = () => {
        setRunning(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb text-size">
                            <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                            <li className="breadcrumb-item"><Link to={"/projects"}>Projects</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Show</li>
                        </ol>

                    </nav>
                </div>
                <div className="col-md-4 text-end">
                    {
                        running ?
                            <button type="button" onClick={stop} className="btn btn-sm btn-danger">Stop</button> :
                            <button type="button" onClick={run} className="btn btn-sm btn-success">Run project</button>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div id="mynetwork" ref={container} style={{ height: clientHeight }}></div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <ConsoleTerminal params={data} />
                        </div>
                    </div>
                    <Button variant="secondary" size="sm" onClick={handleClose} className="me-2 mt-2">Close</Button>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ShowProjects;