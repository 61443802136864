export function getIdentityProvider() {
    return "https://account.idtolu.net/";
};

export function getIdentityRealm() {
    return 'IDT-IDT-DTC-RID-001';
};

export function getIdentityClient() {
    return 'IDT-IDT-DTC-TFE-001';
};

export function getHostNameClient() {
    return 'https://testbed.idtolu.net';
};

export function getHostNameServer() {
    return `https://backend.idtolu.net/api/v1`;
};

export function getHostNameWss() {
    return `wss://backend.idtolu.net`;
};