import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

import axios from "axios";
import { getHostNameServer } from "../../../services/helpers/config";
import { Button, ButtonGroup, Col, Container, ListGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import LayoutPrivate from "../../public/layouts/layout-private";

const Users = () => {

    const { keycloak, initialized } = useKeycloak(); // Instancia de Keycloak
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    /**const [sessions, setSessions] = useState([]);
    let table = null;
    const tableRef = useRef();*/

    useEffect(() => {
        console.log(initialized);
        getUsers();
    }, []);


    /**const getClients = async () => {
        const response = await axios.get(`${getHostNameServer()}/users/clients`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then((result) => {
            if (result.data.length > 0) {
                ///setUsers(result.data);
            }
            console.log(result.data);
        }).catch(error => {
            console.log(error);
        });
    }*/

    const getUsers = async () => {
        await axios.get(`${getHostNameServer()}/users`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
        .then((result) => {
            if (result.data.length > 0) {
                setUsers(result.data);
            }
            ///console.log(result.data);
        }).catch(error => {
            console.log(error);
        });
    }

    const getRoles = (userId) => {
        axios.get(`${getHostNameServer()}/users/${userId}/roles`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then((result) => {
            ///console.log(result.data.realmMappings);
            if (result.data.realmMappings.length > 0) {
                setRoles(result.data.realmMappings);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    const getGroups = (userId) => {
        axios.get(`${getHostNameServer()}/users/${userId}/groups`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
        .then((result) => {
            ///console.log(result.data);
            if (result.data.length > 0) {
                setGroups(result.data);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /**const getSessions = (userId) => {
        axios.get(`${getHostNameServer()}/users/${userId}/sessions`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
        .then((result) => {
            ///console.log(result.data);
            setSessions(result.data);
        }).catch(error => {
            console.log(error);
        });
    }*/

    const popoverRoles = (
        <Popover id="popover-basic">
            <Popover.Body>
                <ListGroup>
                    {roles.map((role, _i, _array) => {
                        return <ListGroup.Item key={role.name}>{role.name}</ListGroup.Item>
                    })}
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    const popoverGroups = (
        <Popover id="popover-basic">
            <Popover.Body>
                <ListGroup>
                    {groups.map((group, _i, _array) => {
                        return <ListGroup.Item key={group.name}>{group.name}</ListGroup.Item>
                    })}
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    /**const popoverSessions = (
        <Popover id="popover-basic">
            <Popover.Body>
                <ListGroup>
                    <ListGroup.Item>{sessions.length}</ListGroup.Item>
                </ListGroup>
            </Popover.Body>
        </Popover>
    );*/

    const buttonDetails = (row = {}) => {
        return (
            <ButtonGroup aria-label="Basic example">
                <OverlayTrigger onEnter={() => getRoles(row.id)} placement="left-start" overlay={popoverRoles}><Button variant="outline-secondary" size="sm">Roles</Button></OverlayTrigger>
                <OverlayTrigger onEnter={() => getGroups(row.id)} placement="bottom" overlay={popoverGroups}><Button variant="outline-secondary" size="sm">Groups</Button></OverlayTrigger>
                {/*<OverlayTrigger onEnter={() => getSessions(row.id)} placement="right-end" overlay={popoverSessions}><Button variant="outline-secondary" size="sm">Sessions</Button></OverlayTrigger>*/}
            </ButtonGroup>
        );
    }

    const columns = [
        {
            name: 'UserName',
            selector: row => row.username,
            sortable: true
        },
        {
            name: 'Full Name',
            selector: row => row.firstName + ' ' + row.lastName,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true
        },
        /*{
            name: 'Status',
            selector: row => <Badge pill bg={row.enabled ? 'success' : 'secondary'}>i</Badge>,
            sortable: true
        },*/
        {
            name: 'Details',
            selector: row => buttonDetails(row),
            sortable: true
        },
    ];

    /**const textSearching = (
        <Form acceptCharset="utf-8">
            <Row>
                <Col>
                    <InputGroup>
                        <FormControl
                            placeholder="Recipient's username"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            required
                        />
                        <Button type="submit" variant="outline-primary" size="sm" id="button-addon2">Search</Button>
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );*/

    /**if (users.length <= 0) {
        return (<div className="text-center mt-5"> <h2><Spinner animation="border" variant="primary" /> Loading...</h2></div>)
    }*/

    return (
        <>
            <LayoutPrivate />
            <Container>
                <h3 className="text-dark mb-3 text-center">List Users</h3>
                <Row>
                    <Col>
                        <div className="table-responsive">
                            <DataTable
                                columns={columns}
                                data={users}
                                ///selectableRows
                                ///subHeader
                                ///subHeaderComponent={textSearching}
                                pagination />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );

}
export default Users;