import React from "react";
import LayoutPrivate from "../layouts/layout-private";

const Guide = () => {

    return (
        <>
            <LayoutPrivate />
        </>
    );
}
export default Guide;