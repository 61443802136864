import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useRef } from "react";
import { Terminal } from "xterm";
import { FitAddon } from 'xterm-addon-fit';
import { WebLinksAddon } from "xterm-addon-web-links";
///import { AttachAddon } from 'xterm-addon-attach';
import "xterm/css/xterm.css";

const ConsoleTerminal = ({ params }) => {

    const { keycloak, initialized } = useKeycloak();
    ///const showMsg = 'Hello World';

    let terminal = null;
    const terminalId = useRef();
    ///let command = '';
    let isWebglEnabled = false;
    let socket = null;

    let baseTheme = {
        foreground: '#F8F8F8',
        background: '#2D2E2C',
        selection: '#5DA5D533',
        black: '#1E1E1D',
        brightBlack: '#262625',
        red: '#CE5C5C',
        brightRed: '#FF7272',
        green: '#5BCC5B',
        brightGreen: '#72FF72',
        yellow: '#CCCC5B',
        brightYellow: '#FFFF72',
        blue: '#5D5DD3',
        brightBlue: '#7279FF',
        magenta: '#BC5ED1',
        brightMagenta: '#E572FF',
        cyan: '#5DA5D5',
        brightCyan: '#72F0FF',
        white: '#F8F8F8',
        brightWhite: '#FFFFFF'
    };

    /**let otherTheme = {
        foreground: '#eff0eb',
        background: '#282a36',
        selection: '#97979b33',
        black: '#282a36',
        brightBlack: '#686868',
        red: '#ff5c57',
        brightRed: '#ff5c57',
        green: '#5af78e',
        brightGreen: '#5af78e',
        yellow: '#f3f99d',
        brightYellow: '#f3f99d',
        blue: '#57c7ff',
        brightBlue: '#57c7ff',
        magenta: '#ff6ac1',
        brightMagenta: '#ff6ac1',
        cyan: '#9aedfe',
        brightCyan: '#9aedfe',
        white: '#f1f1f0',
        brightWhite: '#eff0eb'
    };*/

    useEffect(() => {        
        console.log(initialized, socket);
        ///let isBaseTheme = true;

        terminal = terminalId.current && new Terminal({ fontFamily: '"Cascadia Code", Menlo, monospace', theme: baseTheme, cursorBlink: true });
        ///const attachAddon = new AttachAddon(socket, {bidirectional: false,  inputUtf8: true});
        const fitAddon = new FitAddon();
        ///terminal.loadAddon(attachAddon);
        terminal.loadAddon(new WebLinksAddon());
        terminal.loadAddon(fitAddon);
        terminal.open(terminalId.current);
        fitAddon.fit();

        const prompt = (term) => {
            command = '';
            term.write(`\r\n${keycloak.idTokenParsed.preferred_username}@testing$ `);
        }
        let command = '';
        let commands = {
            clear: {
                f: () => {
                    terminal.clear();
                }
            },
            help: {
                f: () => {
                    terminal.writeln([
                        'Welcome to xterm.js! Try some of the commands below.',
                        '',
                        ...Object.keys(commands).map(e => `  ${e.padEnd(10)} ${commands[e].description}`)
                    ].join('\n\r'));
                    prompt(terminal);
                },
                description: 'Prints this help message',
            },
            ls: {
                f: () => {
                    terminal.writeln(['a', 'bunch', 'of', 'fake', 'files'].join('\r\n'));
                    terminal.prompt(terminal);
                },
                description: 'Prints a fake directory structure'
            },
            loadtest: {
                f: () => {
                    let testData = [];
                    let byteCount = 0;
                    for (let i = 0; i < 50; i++) {
                        let count = 1 + Math.floor(Math.random() * 79);
                        byteCount += count + 2;
                        let data = new Uint8Array(count + 2);
                        data[0] = 0x0A; // \n
                        for (let i = 1; i < count + 1; i++) {
                            data[i] = 0x61 + Math.floor(Math.random() * (0x7A - 0x61));
                        }
                        // End each line with \r so the cursor remains constant, this is what ls/tree do and improves
                        // performance significantly due to the cursor DOM element not needing to change
                        data[data.length - 1] = 0x0D; // \r
                        testData.push(data);
                    }
                    let start = performance.now();
                    for (let i = 0; i < 1024; i++) {
                        for (const d of testData) {
                            terminal.write(d);
                        }
                    }
                    // Wait for all data to be parsed before evaluating time
                    terminal.write('', () => {
                        let time = Math.round(performance.now() - start);
                        let mbs = ((byteCount / 1024) * (1 / (time / 1000))).toFixed(2);
                        terminal.write(`\n\r\nWrote ${byteCount}kB in ${time}ms (${mbs}MB/s) using the ${isWebglEnabled ? 'webgl' : 'canvas'} renderer`);
                        terminal.prompt();
                    });
                },
                description: 'Simulate a lot of data coming from a process'
            }
        };
        const runCommand = (term, text) => {
            const command = text.trim().split(' ')[0];
            if (command.length > 0) {
                term.writeln('');
                if (command in commands) {
                    commands[command].f();
                    return;
                }
                term.writeln(`${command}: command not found`);
            }
            prompt(term);
        }
        const runFakeTerminal = () => {
            if (terminal._initialized) {
                return;
            }
            terminal._initialized = true;
            terminal.prompt = () => {
                terminal.write('\r\n$ ');
            }
            /// TODO: Use a nicer default font
            terminal.writeln([
                '\x1b[32mID:TOLÚ\x1b[0m All Right Reserved',
            ].join('\n\r'));
            ///terminal.writeln('Below is a simple emulated backend, try running `help`.');
            addDecoration(terminal);
            prompt(terminal);

            terminal.onData(e => {
                switch (e) {
                    case '\u0003': // Ctrl+C
                        terminal.write('^C');
                        prompt(terminal);
                        break;
                    case '\r': // Enter
                        runCommand(terminal, command);
                        command = '';
                        break;
                    case '\u007F': // Backspace (DEL)
                        // Do not delete the prompt
                        if (terminal._core.buffer.x > 2) {
                            terminal.write('\b \b');
                            if (command.length > 0) {
                                command = command.substr(0, command.length - 1);
                            }
                        }
                        break;
                    default: // Print all other characters for demo
                        if (e >= String.fromCharCode(0x20) && e <= String.fromCharCode(0x7B) || e >= '\u00a0') {
                            command += e;
                            terminal.write(e);
                        }
                }
            });
        }
        runFakeTerminal();
    }, [terminalId]);

    const addDecoration = (term) => {
        const marker = term.addMarker(15);
        const decoration = term.registerDecoration({ marker, x: 44 });
        decoration.onRender(element => {
            element.classList.add('link-hint-decoration');
            element.innerText = 'Try clicking italic text';
            // must be inlined to override inlined width/height coming from xterm
            element.style.height = '';
            element.style.width = '';
        });
    }

    return (
        <>
            {<div className="w-100">
                <div ref={terminalId}></div>
            </div>}
        </>
    );
}
export default ConsoleTerminal;