import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from './services/keycloak';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Users from './components/private/users/users';
import Error404 from './components/public/errors/404';
import Projects from './components/private/projects/projects';
import CreateProject from './components/private/projects/project-create';
import EditProject from './components/private/projects/projects-edit';
import ShowProjects from './components/private/projects/show-projects';
import Run from './components/private/run/run';
import Welcome from './components/public/welcome/welcome';
import Guide from './components/public/docs/guide';
import Init from './components/private/dashboard/init';
import Admin from './components/private/admin/admin';
import Booking from './components/private/bookings/booking';
import Devices from './components/private/devices/devices';
import { Spinner } from 'react-bootstrap';

const PrivateRoute = ({ children }) => {

  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  return isLoggedIn ? children : null;

};
const AuthRoutes = () => {

  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', minHeight: '800px', alignItems: 'center' }}>
        <h2 style={{ textAlign: 'center' }}>
          <Spinner animation="border" variant="primary" />{" "}
          Loading...
        </h2>
      </div>
    );
  }

  return (
    <Routes>
      {keycloak.authenticated && (
        <>
          <Route path="/" element={<Init />} />
          {keycloak.hasRealmRole('super-admin') && (
            <>
              <Route path="dashboard" element={<Admin />} />
              <Route path="devices" element={<Devices />} />
              <Route path="users" element={<Users />} />
            </>
          )}
          <Route path="dashboard" element={<Run />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/create" element={<CreateProject />} />
          <Route path="projects/:id/:name/edit" element={<EditProject />} />
          <Route path="projects/:id/:name" element={<ShowProjects />} />
          <Route path="run" element={<Run />} />
          <Route path="*" element={<Error404 />} />
        </>
      )}
      <>
        <Route path="/" element={<Welcome />} >
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/guide" element={<Guide />} />
      </>
    </Routes>
  );
}
function App() {
  return (
    <div>
      <ReactKeycloakProvider authClient={keycloak}> {/* provider authentication keycloak*/}
        <AuthRoutes /> {/* component of routes */}
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
