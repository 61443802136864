import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { getHostNameServer, getHostNameWss } from "../../../services/helpers/config";

const Status = () => {

    const { keycloak, initialized } = useKeycloak(); // hook for keycloak
    const [devices, setDevices] = useState([]);
    const [socket, setSocket] = useState(null);
    const [slices, setSlices] = useState([]);

    useEffect(() => {
        console.log(initialized, socket);
        getSlices();
        getDevices();
    }, []);

    useEffect(() => {

        const token = Date.now();

        // se hace una instancia de WebSocket de javascript
        const newSocket = new WebSocket(`${getHostNameWss()}/live/nodes/${token}`);

        // carga la instancia dentro de la variable socket declarada del hook useState
        setSocket(newSocket);

        // evento que se genera al iniciar la conexxion satisfactoriamente
        newSocket.onopen = () => {
            newSocket.send(token);
        }

        // evento que captura el mensaje recibido del servidor
        newSocket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            if (data.results.length > 0) {
                setDevices(data.results);
            }
        }

        // evento que captura el error
        newSocket.onerror = () => {
            console.log('Error Server');
        }

        return () => newSocket.close();
    }, [setSocket]);

    const getSlices = async () => {
        /** load data bookings from sever **/
        await axios.get(`${getHostNameServer()}/slices`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            setSlices(res.data);
        }).catch((err) => {
            console.error(err);
        });
    }

    const getDevices = async () => {
        /// obtain all devices registered in the server
        await axios.get(`${getHostNameServer()}/devices/anechoic_chamber`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            setDevices(res.data.results);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getAccess = (device = {}) => {
        let ip = '0.0.0.0';
        if(device.primary_ip4 !== null && typeof device.primary_ip4 !== 'undefined' && typeof device.primary_ip4.address !== 'undefined') {
            ip = device.primary_ip4.address;
        }
        return ip;
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th style={{fontSize: '12px'}}>#</th>
                    <th style={{fontSize: '12px'}}>Name</th>
                    {/**<th>Address</th>*/}
                    <th className="text-center" style={{fontSize: '12px'}}>Status</th>
                    <th className="text-center" style={{fontSize: '12px'}}>Metal O.S</th>
                    <th className="text-center" style={{fontSize: '12px'}}>Access it</th>
                </tr>
            </thead>
            <tbody>
                {devices.length > 0 ? (
                    devices.map((device, key, array) => {
                        return (
                            <tr key={device.id}>
                                <th style={{fontSize: '12px'}}>{key + 1}</th>
                                <td style={{fontSize: '12px'}}>{device.device_type.display}</td>
                                {/*<td>{device.primary_ip4 ? device.primary_ip4.address : 'No assigned'}</td>*/}
                                <td className="text-center" style={{fontSize: '12px'}}>
                                    {device.status.value === "active" ? (
                                        <img src="/icons/done_devices.svg" width={15} alt="" />
                                    ) : (
                                        <img src="/icons/noactive_devices.svg" width={20} alt="" />
                                    )}
                                </td>
                                <td className="text-center" style={{fontSize: '12px'}}>
                                    {device.comments.startsWith("Ubuntu") ? (
                                        <img src="/icons/ubuntu.png" width={20} alt="" />
                                    ) : (<img src="/icons/noactive_devices.svg" width={20} alt="" />)}
                                    {/** {device.comments.startsWith("Apple") ? (
                                        <img src="/icons/macos.png" width={20} />
                                    ) : ''}
                                    {device.comments.startsWith("Fedora") ? (
                                        <img src="/icons/fedora.png" width={20} />
                                    ) : ''}
                                    {device.comments.startsWith("Debian") ? (
                                        <img src="/icons/fedora.png" width={20} />
                                    ) : ''} */}
                                </td>
                                <td style={{fontSize: '12px'}}>{(slices.length > 0 ? 'ssh ' + /*slides[0].ssh_slide*/'idtolu' + '@' + getAccess(device) : '')}</td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={5}>
                            <div className="text-center mt-4">
                                {" "}
                                <h2>
                                    <Spinner animation="border" variant="primary" />{" "}
                                    Loading...
                                </h2>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
export default Status;