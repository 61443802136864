import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { getHostNameServer } from "../../../services/helpers/config"; // ruta de acceso al servidor
import { Container, Spinner } from "react-bootstrap";
import LayoutPrivate from "../../public/layouts/layout-private";

const Projects = () => { // declaración del componente Projects, para listar las plantillas diseñadas con vis-network

    const { keycloak, initialized } = useKeycloak(); // Instancia de Keycloak
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log(initialized, toggleModal);
        /** get All nodes */
        getProjects(); // se carga la lista de proyectos registrados del usuario.
    }, []);

    const getProjects = async () => { // Para extraer los proyectos/plantillas de guardadas desde el servidor.
        await axios.get(`${getHostNameServer()}/projects/me`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => { // debe asignarse el token de usuario
            //console.log(res.data);
            setProjects(res.data); // Agrega la respuesta del servidor al estado data
        }).catch(err => {
            console.error(err); // muestra por consola si existe un error
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const onDeleteProject = (id) => { // para borrar una plantilla
        const validate = window.confirm("This project will be permanently removed.\nYou want to continue the operation?"); // confirmación para eliminar la plantilla
        if (validate) {
            axios.delete(`${getHostNameServer()}/projects/${id}`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then((result) => {
                if (result.data.delete) {
                    getProjects();
                    console.log('Deleted');
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                getProjects();
            });
        } else {
            console.log("Operación cancelada");
        }
    }

    const setSpinner = () => {
        return (isLoading) ? <div> <h2><Spinner animation="border" variant="primary" /> Loading...</h2></div> : '';
    }

    return (
        <>
        <LayoutPrivate/>
            <Container>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Projects</li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col">
                        <h4 className="text-title">Hi, Welcome to TestBed Creator IDTOLU</h4>
                    </div>
                    <div className="col">
                        <div className="text-end">
                            <Link to={"/projects/create"} target="_blank" rel="noopener" className="btn btn-sm btn-dark">New project</Link>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">
                    {projects.length > 0 ? projects.map((element, _index) => (
                        <div className="col" key={element.id}> {/** creación de card Bootstrap */}
                            <div className="card h-100">
                                {/*<img src="..." className="card-img-top" alt="..." />*/}
                                <div className="card-body">
                                    <h5 className="card-title">{element.name}</h5>{/*<Button variant="light" size="sm"><span className="material-icons">edit</span></Button>*/}
                                    {/*<p className="card-text"><img src="/icons/5g.png" /></p>*/}
                                    <p>
                                        <Link to={`/projects/${element.id}/${element.name}`} className="btn btn-sm btn-outline-info me-1"><span className="material-icons">visibility</span></Link>
                                        <Link to={`/projects/${element.id}/${element.name}/edit`} className="btn btn-sm btn-outline-secondary me-1"><span className="material-icons">edit</span></Link>
                                        <button onClick={() => onDeleteProject(element.id) /** borrar plantilla */} className="btn btn-sm btn-outline-danger"><span className="material-icons">delete</span></button>
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">Created: {moment(element.created).fromNow() /**para calcular el tiempo de creación */}</small><br />
                                    <small className="text-muted">Last updated: {moment(element.updated).fromNow() /**para calcular el tiempo de ultima actualización */}</small>
                                </div>
                            </div>
                        </div>
                    )) : setSpinner()}
                </div>
            </Container>
        </>
    );
}
export default Projects;