import React from "react";

const Footer = () => {
    return (
        <div className="container" style={{ 'marginTop': '220px' }}>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <p className="col-md-4 mb-0 text-muted">&copy; 2023 <a href="https://www.idtolu.com" className="text-primary" target="_blank">IDTOLU</a> - SAS</p>

                <a href="https://www.idtolu.com" target={'_blank'} className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                    <img className="me-2" style={{ 'verticalAlign': '-.125em', 'fill': 'currentcolor' }} src="/icons/header_logo.png" width="80" height="32" />
                </a>

                <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item"><a href="https://www.idtolu.com" target={'_blank'} className="nav-link px-2 text-muted">Ir al sitio</a></li>
                    {/*<li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Features</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Pricing</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li>
                    <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>*/}
                </ul>
            </footer>
        </div>
    );
}
export default Footer;