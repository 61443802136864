import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";
import { useKeycloak } from "@react-keycloak/web";
import { getHostNameServer, getHostNameWss } from "../../../services/helpers/config";
import axios from "axios";
import "../bookings/style.css";
import "../shell/shell.css"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Status from "../devices/status";
import LayoutPrivate from "../../public/layouts/layout-private";

const Run = () => {

    const { keycloak, initialized } = useKeycloak(); /// hook for keycloak
    const [socket, setSocket] = useState(null);

    /**
     * States shell
     */
    const [shell, setShell] = useState(false);
    const showShell = () => setShell(true);
    /**const hideShell = () => setShell(false);*/

    /**
     * Create slice
     */
    const [slices, setSlices] = useState([]); //  status list bookings
    const [slice, setSlice] = useState({ sshSlice: '', user: {} }); //  status list bookings
    const [sliceCreating, setSliceCreating] = useState(false);
    const [sliceDeleting, setSliceDeleting] = useState(false);

    /**
     * Modal create Booking
     */
    const [booking, setBooking] = useState({
        slice_id: "",
        start: "",
        end: "",
        title: "Untitled",
        description: "",
        user: {}
    }); // status new booking
    const [bookings, setBookings] = useState([]); //  status list bookings
    const [bookingCreating, setBookingCreating] = useState(false);
    const [modalAddBooking, setModalAddBooking] = useState(false);
    const closeModalAddBooking = () => setModalAddBooking(false);
    const openModalAddBooking = () => setModalAddBooking(true);

    /**
     * Modal list keys
     */

    /**const [listKeys, setListKeys] = useState(false);*/
    const [modalListKeys, setModalListKeys] = useState(false);
    const closeModalListKeys = () => setModalListKeys(false);
    const openModalListKeys = () => setModalListKeys(true);

    /**
     * Modal create Keys states
     */
    const [key, setKey] = useState({ sshKey: '', ssh_slice_id: '', user: {} }); // status of params join key
    const [modalAddKeys, setModalAddKeys] = useState(false);
    const closeModalAddKeys = () => setModalAddKeys(false);
    const openModalAddKeys = () => setModalAddKeys(true);
    const [keyCreating, setKeyCreating] = useState(false);
    const [keyDeleting, setKeyDeleting] = useState(false);
    /**const [localUserKey, setLocalUserKey] = useState("");*/

    const timeZone = moment.tz.guess()/*"Asia/Jerusalem"*/;
    const [zone, setZone] = useState(timeZone);

    useEffect(() => {
        console.log(initialized, socket);
        getBookings();
        getSlices();
    }, []);

    useEffect(() => {
        const token = Date.now();
        /**
         * se hace una instancia de WebSocket de javascript
         * 
         */
        const newSocket = new WebSocket(`${getHostNameWss()}/live/bookings/${token}`);
        // carga la instancia dentro de la variable socket declarada del hook useState
        setSocket(newSocket);
        // evento que se genera al iniciar la conexxion satisfactoriamente
        newSocket.onopen = () => {
            newSocket.send(token);
        }

        /// evento que captura el mensaje recibido del servidor
        newSocket.onmessage = (message) => {
            let data = [];
            data = JSON.parse(message.data);
            if (data.length > 0) {                
                setBookings(formatBookings(data));
            }
        }

        /// evento que captura el error
        newSocket.onerror = () => {
            console.log('Error Server');
        }

        return () => newSocket.close();
    }, [setSocket]);

    const formatBookings = (data = []) => {
        const formatted = data.map((element, index, array) => {
            array[index].start = moment(element.start).clone().tz(zone).format("YYYY-MM-DDTHH:mm:ss");
            array[index].end = moment(element.end).clone().tz(zone).format("YYYY-MM-DDTHH:mm:ss");
            if (moment().isBetween(moment(element.start), moment(element.end))) {
                array[index].overlap = false;
                array[index].removable = false;
                array[index].editable = false;
                array[index].backgroundColor = '#B3B3B3';
            }
            if (moment().isAfter(element.end)) {
                array[index].display = 'background';
                array[index].overlap = false;
                array[index].removable = false;
                array[index].editable = false;
            }
            if (moment().isBefore(element.start) && keycloak.idTokenParsed.sub === element.tokenId) {
                array[index].overlap = false;
                array[index].removable = true;
                array[index].editable = true;
            }
            if (moment().isBefore(element.start) && keycloak.idTokenParsed.sub !== element.tokenId) {
                array[index].overlap = false;
                array[index].removable = false;
                array[index].editable = false;
            }
            return array;
        });
        return formatted;
    }

    const onSubmitBooking = async (event) => {
        event.preventDefault();
        setBookingCreating(true);
        const toastId = toast.loading("Requesting...");
        await axios.post(
            `${getHostNameServer()}/slices/bookings/validate`, booking, { headers: { Authorization: `Bearer ${keycloak.token}` } }
        ).then((res) => {
            if (res.data.response === "invalid_min") {
                toast.update(toastId, { render: "It is only allowed a minimum of one hour", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (res.data.response === "invalid_max") {
                toast.update(toastId, { render: "It is only allowed maximum 4 hours", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (res.data.response === "valid") {
                closeModalAddBooking();
                toast.update(toastId, { render: "Successfuly", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
            }
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setBookingCreating(false);
            getBookings();
        });
    }

    const handleChangeBooking = (event) => { // register changes into form devices
        const fields = { // fields of object for sending to server
            slice_id: booking.slice_id,
            start: booking.start,
            end: booking.end,
            title: booking.title,
            user: {
                token_id: keycloak.idTokenParsed.sub,
            }
        };
        fields[event.target.name] = event.target.value;
        setBooking(fields);
    }

    const getBookings = async () => {
        /** load data bookings from sever */
        await axios.get(`${getHostNameServer()}/slices/bookings/me`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            const dates = res.data;
            dates.map((element, index, array) => {
                array[index].start = moment(element.start).clone().tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");
                array[index].end = moment(element.end).clone().tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");
                if (moment().isBetween(moment(element.start), moment(element.end))) {
                    array[index].overlap = false;
                    array[index].removable = false;
                    array[index].editable = false;
                    array[index].backgroundColor = '#B3B3B3';
                }
                if (moment().isAfter(element.end)) {
                    array[index].display = 'background';
                    array[index].overlap = false;
                    array[index].removable = false;
                    array[index].editable = false;
                }
                if (moment().isBefore(element.start) && keycloak.idTokenParsed.sub === element.tokenId) {
                    array[index].overlap = false;
                    array[index].removable = true;
                    array[index].editable = true;
                }
                if (moment().isBefore(element.start) && keycloak.idTokenParsed.sub !== element.tokenId) {
                    array[index].overlap = false;
                    array[index].removable = false;
                    array[index].editable = false;
                }
                return array;
            });
            setBookings(dates); // Adding the array obtained from the server the state
        }).catch((err) => {
            console.error(err);
        });
    }

    const onSubmitKey = async (event) => { // submit data form about new device key
        event.preventDefault();
        setKeyCreating(true);
        const toastId = toast.loading("Saving key... please wait", { autoClose: 5000 });
        await axios.post(`${getHostNameServer()}/slices/keys`, key, { headers: { 'Authorization': `Bearer ${keycloak.idToken}` } }).then(result => {
            if (result.data.status === 'exist') {
                toast.update(toastId, { render: "This Key already exists", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (result.data.status === 'added') {
                toast.update(toastId, { render: "Key successfully added", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                setKey({ sshKey: '', ssh_slice_id: '', user: null });
                closeModalAddKeys();
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setKeyCreating(false);
            getSlices();
        });
    }

    const handleChangeKey = (event) => { // register changes into form devices
        const fields = { // fields of object for sending to server
            sshKey: key.sshKey,
            ssh_slice_id: key.ssh_slice_id,
            user: {
                token_id: keycloak.idTokenParsed.sub,
            }
        };
        fields[event.target.name] = event.target.value;
        /**if (!fields.ssh_key.startsWith("ssh-rsa ") || fields.ssh_key.indexOf('@') === -1 || fields.ssh_slice_id === '') {
            setKeyCreating(true);
        } else {
            setKeyCreating(false);
        }*/
        setKey(fields);
    }

    const deleteKey = async (slice_id, id) => {
        let keyId = id;
        let sliceId = slice_id;
        /** load data bookings from sever */
        if (window.confirm("You want to permanently remove this key?")) {
            setKeyDeleting(true);
            const toastId = toast.loading("Deleting key... please wait", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/slices/keys/${sliceId}/${keyId}`, {
                headers: { Authorization: `Bearer ${keycloak.idToken}` },
            }).then((res) => {
                if (res.data.status === true) {
                    toast.update(toastId, { render: "Key successfully deleted", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                    closeModalListKeys();
                } else if (res.data.status === false) {
                    toast.update(toastId, { render: "This key does not exist", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
                }
            }).catch((err) => {
                console.error(err);
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            }).finally(() => {
                setKeyDeleting(false);
                getSlices();
            });
        }
    }

    const handleChangeFileKeyPub = (event) => { // register changes into form devices
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            key.sshKey = e.target.result.trim();
            setKey({ sshKey: key.sshKey });
            handleChangeKey(event);
        };
    }

    const deleteBooking = async (bookingId) => {
        const toastId = toast.loading("Deleting booking...");
        await axios.delete(`${getHostNameServer()}/slices/bookings/${bookingId}`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            if (res.data.status === true) {
                toast.update(toastId, { render: "Deleted Successfully", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (res.data.status === false) {
                toast.update(toastId, { render: "This reservation could not be eliminated.", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            }
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            console.log(err);
        }).finally(() => {
            getBookings();
        });
    }

    const eventClick = (info) => {
        let bookingID = info.event.id;
        if (info.event.extendedProps.removable) {
            if (window.confirm(`Are you sure you want to delete the event '${info.event.title}'`)) {
                deleteBooking(bookingID);
                info.event.remove();
            }
        }
    }

    const eventChange = async (info) => {
        // If there is an event change, this function determines if the reservation event time is valid.
        const startSelect = moment(info.event.startStr).clone().tz(timeZone);
        const endSelect = moment(info.event.endStr).clone().tz(timeZone);
        const diff = endSelect.diff(startSelect, 'minutes');
        const idToast = toast.loading("Trying...");
        if ((diff < 30 && diff > 0) || (diff > 240 && diff > 0)) {
            toast.update(idToast, { render: `Invalid booking event schedule!`, type: "error", theme: "dark", closeOnClick: true, isLoading: false });
            info.revert();
        } else {
            await axios.put(`${getHostNameServer()}/slices/bookings/${info.event.id}/me`, {
                // validate range hours
                start: moment(info.event.startStr).clone().tz(timeZone).valueOf(),
                end: moment(info.event.endStr).clone().tz(timeZone).valueOf(),
                title: info.event.title,
            }, { headers: { Authorization: `Bearer ${keycloak.token}` } }).then((res) => {
                if (res.data.response === "updated") {
                    toast.update(idToast, { render: `Booking event schedule has been updated!`, type: "success", theme: "light", autoClose: true, closeOnClick: true, isLoading: false });
                } else {
                    toast.update(idToast, { render: `Failed operation. Something's wrong!`, type: "error", theme: "dark", hideProgressBar: true, closeOnClick: true, isLoading: false });
                }
            }).catch((err) => {
                toast.update(idToast, { render: `Failed operation. Something's wrong: ${err.response.statusText}`, type: "error", theme: "dark", closeOnClick: true, isLoading: false });
            }).finally(() => {
                getBookings();
            });
        }
    }

    const eventSelectOverlap = (event) => {
        // if it is busy it will not allow you to select that date
        return event.rendering === "background";
    }

    const eventDateSelect = async (info) => {
        // if the date is unoccupied it will allow selecting that date
        //console.log("Colombia: " + moment(info.startStr).clone().tz(timeZone).format());
        //console.log("Europa Madrid: " + moment(info.startStr).clone().tz("Europe/Madrid").format());
        booking.start = moment(info.startStr).clone().tz(timeZone).valueOf();
        booking.end = moment(info.endStr).clone().tz(timeZone).valueOf();
        openModalAddBooking();
    }

    const eventSelectAllow = (info) => {
        // Validates that the date and time are not old
        const selected = moment(info.startStr);
        const current = moment();
        const diff = selected.diff(current, 'minutes');
        // moment is a pluging for manipulation of dates
        return (diff > 30 && diff > 0);
    }

    const handleChangeSlice = (event) => { // register changes into form devices
        const fields = { // fields of object for sending to server
            sshSlice: slice.sshSlice,
            user: {
                token_id: keycloak.idTokenParsed.sub,
                userName: keycloak.idTokenParsed.preferred_username,
                email: keycloak.idTokenParsed.email,
                firstName: keycloak.idTokenParsed.given_name,
                lastName: keycloak.idTokenParsed.family_name
            }
        };
        fields[event.target.name] = event.target.value;
        setSlice(fields);
    }

    const getSlices = async () => {
        /** load data bookings from sever **/
        await axios.get(`${getHostNameServer()}/slices`, {
            headers: { Authorization: `Bearer ${keycloak.token}` },
        }).then((res) => {
            setSlices(res.data);
        }).catch((err) => {
            console.error(err);
        });
    }

    const createSlice = async (event) => {
        event.preventDefault();
        setSliceCreating(true);
        const toastId = toast.loading("Requesting to slice please wait", { autoClose: 5000 });
        await axios.post(
            `${getHostNameServer()}/slices`, slice,
            { headers: { Authorization: `Bearer ${keycloak.token}` } }
        ).then((res) => {
            if (res.data.status === "exist") {
                toast.update(toastId, { render: "This slice must be unique", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (res.data.status === "added") {
                toast.update(toastId, { render: "Slice successfully added", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
            } else if (res.data.status === "error") {
                toast.update(toastId, { render: "Registration error", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            }
        }).catch((err) => {
            toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
            console.error(err);
        }).finally(() => {
            setSliceCreating(false);
            getSlices();
        });
    }

    const deleteSlice = async (id) => {
        if (window.confirm("If you delete this slice all your Keys and Bookings will also be deleted.\nyou want to continue?")) {
            setSliceDeleting(true);
            const toastId = toast.loading("Deleting slice... " + slices[0].sshSlice + "...", { autoClose: 5000 });
            await axios.delete(`${getHostNameServer()}/slices/${id}`,
                { headers: { Authorization: `Bearer ${keycloak.token}` } }
            ).then((res) => {
                if (res.data.status === false) {
                    toast.update(toastId, { render: "This slice does not exist", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
                } else if (res.data.status === true) {
                    toast.update(toastId, { render: "Slice successfully deleted", type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
                }
            }).catch((err) => {
                toast.update(toastId, { render: `We are having problems: ${err.response.statusText}`, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
                console.error(err);
            }).finally(() => {
                setSliceDeleting(false);
                getSlices();
            });
        }
    }

    return (
        <>
            <LayoutPrivate />
            <div className="container">
                <h2 className="text-center mb-5 fw-bolder text-font">TESTBED Experimenter Page</h2>
                <Row className="mb-3">
                    <Col className="col-md-3">
                        {slices.length >= 1 ? (
                            <>
                                {/*<Form>
                                    <InputGroup size="sm">
                                        <Form.Control
                                            placeholder="Recipient's slice"
                                            aria-label="Recipient's username with two button addons"
                                        />
                                        <Button variant="outline-primary">Save</Button>
                                        <Button variant="outline-secondary">Cancel</Button>
                                    </InputGroup>
                                </Form>*/}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-listKeys">Show ssh keys for access to the testbed</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            type="button"
                                            onClick={openModalListKeys}
                                            size="sm"
                                            variant="secondary"
                                            style={{ marginTop: "10px" }}
                                        >
                                            keys
                                        </Button>
                                    </span>
                                </OverlayTrigger>{' '}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-newKey">Add a new ssh key for access to the testbed</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            type="button"
                                            onClick={openModalAddKeys}
                                            size="sm"
                                            variant="outline-success"
                                            style={{ marginTop: "10px" }}
                                            disabled={keyCreating}
                                        >
                                            Add key
                                        </Button>
                                    </span>
                                </OverlayTrigger>{' '}
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{"Open web terminal.\nIf after opening the terminal you reload the current page you will lose the session within it."}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            type="button"
                                            onClick={showShell}
                                            size="sm"
                                            style={{ marginTop: "10px" }}
                                            variant="outline-white"><img src="/icons/terminal.svg" style={{ margin: '0px 0px 0px 0px', width: '40px' }} />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </>
                        ) : (
                            <>
                                <Form noValidate onSubmit={createSlice}>

                                    <Form.Label>Slice name {' '}
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">This is the name of your slice which will be used to login via ssh to our platform</Tooltip>}>
                                            <span className="d-inline-block">
                                                <img className="btn-help" src="/icons/help.svg" />
                                            </span>
                                        </OverlayTrigger>
                                    </Form.Label>

                                    <InputGroup size="sm">
                                        <InputGroup.Text id="inputGroupPrepend">idtolu_</InputGroup.Text>
                                        <Form.Control
                                            name="sshSlice"
                                            value={slice.sshSlice}
                                            onChange={handleChangeSlice}
                                            placeholder="Type here your slice name"
                                            aria-label="Recipient's username with two button addons"
                                            autoComplete="off"
                                            required
                                            isInvalid={(slice.sshSlice.length > 0 && slice.sshSlice.length <= 5)}
                                            isValid={(slice.sshSlice.length > 5)}
                                        />
                                        <Button type="submit" variant="outline-primary" disabled={sliceCreating}>Save</Button>
                                    </InputGroup>
                                </Form>
                                {/*<Button
                                    type="button"
                                    onClick={openModalKey}
                                    size="sm"
                                    variant="secondary"
                                    style={{ marginTop: "10px" }}
                                >
                                    Settings & keys
                                </Button>*/}
                            </>
                        )}
                        <ListGroup id="external-events">
                            <div className="text-center fw-bold">Slices</div>
                            {slices.map((slice, i, array) => {
                                return (
                                    <Button className="mb-2" variant="success" size="sm" key={slice.id} onClick={() => deleteSlice(slice.id)} disabled={sliceDeleting}>{`${slice.sshSlice}`}</Button>
                                );
                            })}
                        </ListGroup>
                        <div className="text-muted mt-2">NOTE: To access from your terminal type: <code>ssh {slices.length > 0 ? slices[0].sshSlice : 'idtolu_slicename'}@launa.idtolu.net</code></div>
                    </Col>
                    <Col className="col-md-3">
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                listPlugin,
                                interactionPlugin
                            ]}
                            initialView="timeGridDay"
                            customButtons={{
                                refreshButton: {
                                    text: 'refresh',
                                    click: (event, htmlElement) => {
                                        getBookings();
                                    }
                                }
                            }}
                            headerToolbar={{
                                left: "refreshButton",
                                center: "",
                                right: "",
                            }}
                            timeZone={timeZone}
                            rerenderDelay={10}
                            eventDurationEditable={true}
                            droppable={true}
                            nowIndicator={true}
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            selectAllow={eventSelectAllow}
                            dayMaxEvents={true}
                            weekends={false}
                            events={bookings}
                            contentHeight={"600px"}
                            eventClick={eventClick}
                            //eventResizeStop={eventResizeDate}
                            eventChange={eventChange}
                            select={eventDateSelect}
                            selectOverlap={eventSelectOverlap}
                        />
                    </Col>
                    <Col className="col-md-6">
                        <Status slices={slices} />
                    </Col>
                </Row>
                <Modal
                    show={modalListKeys}
                    onHide={closeModalListKeys}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Your KEYs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {slices.map((slice, _i, _arraySlice) => {
                            return (<div key={slice.id}>
                                <h4>{slice.sshSlice}</h4>
                                {slice.keys.map((key, _j, _arrayKeys) => {
                                    return (
                                        <Row key={key.id} className="mb-2">
                                            <Form.Group
                                                as={Col}
                                                md="12"
                                                className="mb-3"
                                                controlId="description"
                                            >
                                                <div className="text-end">
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => deleteKey(slice.id, key.id)}
                                                        size="sm"
                                                        disabled={keyDeleting}
                                                    >
                                                        X
                                                    </Button>
                                                </div>
                                                <Form.Control
                                                    size="sm"
                                                    as="textarea"
                                                    rows={6}
                                                    value={key.sshKey}
                                                    placeholder="SSH KEY"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Row>
                                    );
                                })}
                            </div>)
                        })}
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={closeModalListKeys}
                            className="me-2"
                        >
                            Close
                        </Button>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modalAddKeys}
                    onHide={closeModalAddKeys}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New KEY</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Form onSubmit={onSubmitKey}>
                                <Form.Group
                                    as={Col}
                                    md="12"
                                    className="mb-3"
                                    controlId="description"
                                >
                                    <Form.Label>Slice</Form.Label>
                                    <Form.Select name="ssh_slice_id" className="mb-3" placeholder="SSH KEY ACCESS" onChange={handleChangeKey} required>
                                        <option value="">Select to slice</option>
                                        {slices.map((slice, i, array) => {
                                            return (<option key={slice.id} value={slice.id}>{slice.sshSlice}</option>)
                                        })}
                                    </Form.Select>
                                    <Form.Label>SSH Key <div className="text-muted">NOTE: If you have not yet generated an ssh key pair Type <code>ssh-keygen</code> in your terminal and press the enter key. Then type <code>cat .ssh/id_rsa.pub</code> and press enter. Now copy the printed key and paste it into the following field.</div></Form.Label>
                                    <Form.Control
                                        className="mb-3"
                                        size="sm"
                                        as="textarea"
                                        rows={8}
                                        name="sshkey"
                                        value={key.sshKey}
                                        placeholder="Preview public ssh key here"
                                        //onChange={handleChangeKey}
                                        required
                                        disabled
                                        isInvalid={(!key.sshKey.startsWith("ssh-rsa ") || (key.sshKey.indexOf('@') === -1))}
                                        isValid={(key.sshKey.startsWith("ssh-rsa ") || (key.sshKey.indexOf('@') !== -1))}
                                    />
                                    <Form.Group controlId="formFileSm" className="mb-3">
                                        <Form.Label>Import SSH Key .pub</Form.Label>
                                        <Form.Control type="file" size="sm" accept=".pub" onChange={handleChangeFileKeyPub} required />
                                    </Form.Group>
                                    <Button variant="secondary" size="sm" onClick={closeModalAddKeys} className="me-2">
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary" size="sm" disabled={keyCreating}>Save key</Button>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={modalAddBooking}
                    onHide={closeModalAddBooking}
                    backdrop="static"
                    keyboard={false}
                    scrollable={true}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-2">
                            <Form onSubmit={onSubmitBooking}>
                                <Form.Group
                                    as={Col}
                                    md="12"
                                    className="mb-3"
                                    controlId="description"
                                >
                                    <Form.Label>Slice</Form.Label>
                                    <Form.Select name="slice_id" className="mb-3" placeholder="SSH KEY ACCESS" onChange={handleChangeBooking} required>
                                        <option value="">Select to slice</option>
                                        {slices.map((slice, i, array) => {
                                            return (<option key={slice.id} value={slice.id}>{slice.sshSlice}</option>)
                                        })}
                                    </Form.Select>
                                    {/*<Form.Label>Start</Form.Label>
                                    <input
                                        className="form-control mb-3"
                                        name="start"
                                        value={booking.start}
                                        onChange={handleChangeBooking}
                                        required
                                    />
                                    <Form.Label>End</Form.Label>
                                    <input
                                        className="form-control mb-3"
                                        name="end"
                                        value={booking.end}
                                        onChange={handleChangeBooking}
                                        required
                                    />*/}
                                    <Button variant="secondary" size="sm" onClick={closeModalAddBooking} className="me-2">
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary" size="sm" disabled={bookingCreating}>Save booking</Button>
                                </Form.Group>
                            </Form>
                        </Row>
                    </Modal.Body>
                </Modal>
                {shell ? (<div className="container-fluid fixed-bottom scroll">
                    {/*<IdShell credentials={slices}/>*/}
                    <iframe title="IDTOLU Web terminal" src={`https://launa.idtolu.net/idshell/ssh/${(slices.length > 0) ? slices[0].sshSlice : ''}?pass=${(slices.length > 0) ? slices[0].sshPassword : ''}`} className="shell scrollpy-example" />
                </div>) : ''}
                <ToastContainer />
            </div>
        </>
    );
};
export default Run;
