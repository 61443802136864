import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getIdentityProvider, getIdentityRealm } from "../../../services/helpers/config";
import Footer from "../footer/footer";

const Init = () => {

    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        console.log(initialized);
    }, []);

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="light" className="shadow mb-5" variant="light">
                <Container fluid>
                    <Navbar.Brand href={"/"}><img src="/icons/header_logo.png" alt="ID:TOLU" width="100%" height="40" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {!!keycloak.authenticated && (
                            <>
                                <Nav className="me-auto">
                                </Nav>
                                <Nav>
                                    {/*<Link to={"/terminal"} className="nav-link"><img src="/icons/terminal.svg" className="float-end" /></Link>*/}
                                    <NavDropdown title={`${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`} id="collasible-nav-dropdown">
                                        <NavDropdown.Item target="_blank" href={`${getIdentityProvider()}/realms/${getIdentityRealm()}/account/`}>Profile</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}` })}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </>
                        )}
                        {!keycloak.authenticated && (
                            <>
                                <Nav className="me-auto">
                                    <Link to={"/"} className="nav-link">Home</Link>
                                    <Link to={"/"} className="nav-link">Docs</Link>
                                    <Link to={"/"} className="nav-link">Features</Link>
                                </Nav>
                                <Nav id="collasible-nav-dropdown">
                                    <Nav.Link onClick={() => keycloak.login()}>Login</Nav.Link>
                                    <Nav.Link onClick={() => keycloak.register()}>Register</Nav.Link>
                                </Nav>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="container container-card">
                <div className="text-dark mb-5">
                    <h4 className="text-title">Hi {keycloak.tokenParsed.given_name}, Welcome!</h4>
                </div>
                <Row xs={1} md={6} className="g-4 mb-3 animate__animated animate__slideInUp">
                    {keycloak.hasResourceRole('unified-cloud') && (
                        <div className="col">
                            <a href="https://cloud.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">ID:TOLÚ Cloud</div>
                                        <p><img src="https://img.icons8.com/color/480/openstack.png" width={40} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-k8s') && (
                        <div className="col">
                            <a href="https://k8s.idtolu.net/#/oauth/callback" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Kubernetes</div>
                                        <p><img src="/icons/kubernetes.png" width={40} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-jenkins') && (
                        <div className="col">
                            <a href="https://cicd.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Jenkins</div>
                                        {/*<p className="card-text">automation server which enables developers around the world to reliably build, test, and deploy their software.</p>*/}
                                        <p>{/*<a href="http://10.0.100.100/jenkins">*/}<img src="https://www.jenkins.io/images/logos/jenkins/jenkins.svg" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-gitlab') && (
                        <div className="col">
                            <a href="https://account.idtolu.net/realms/IDT-IDT-DTC-RID-001/protocol/saml/clients/IDT-IDT-DTC-GLB-006" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">GitLab</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://10.0.100.100/gitlab">*/}<img src="https://about.gitlab.com/images/devops-tools/gitlab-logo.svg" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-idtolu') && (
                        <div className="col">
                            <a href={`${window.location.origin}/dashboard`} target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Testbed</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://localhost:3000/network">*/}<img src="/icons/network.svg" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-iot') && (
                        <div className="col">
                            <a href={`https://iot.idtolu.net`} target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">M-Platform</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://localhost:3000/network">*/}<img src="/icons/mplatform.png" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-automation') && (
                        <div className="col">
                            <a href="https://automation.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Automation</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://localhost:3000/network">*/}<img src="/icons/ansible.png" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-nserver') && (
                        <div className="col">
                            <a href="https://networkserver.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Net Server</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://localhost:3000/network">*/}<img src="/icons/ns.png" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-zabbix') && (
                        <div className="col">
                            <a href="https://account.idtolu.net/realms/IDT-IDT-DTC-RID-001/protocol/saml/clients/https%3A%2F%2Fmonitoring.idtolu.net%3FRelayState%3Dhttps%3A%2F%2Fmonitoring.idtolu.net%2Findex_sso.php " target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Monitoring Tools</div>
                                        {/*<p className="card-text">GitLab is The DevOps Platform, delivered as a single application. This makes GitLab unique and creates a streamlined software workflow, unlocking your organization from the constraints of a pieced together toolchain.</p>*/}
                                        <p>{/*<a href="http://10.0.100.100:8220/">*/}<img src="/icons/monitoring-tools.png" width={40} height={50} alt="" />{/*</a>*/}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-grafana') && (
                        <div className="col">
                            <a href="https://grafana.idtolu.net/login/generic_oauth" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Dashboards</div>
                                        <p><img src="/icons/grafana_logo_icon.png" width={70} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-keycloak') && (
                        <div className="col">
                            <a href="https://account.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">ID Provider</div>
                                        <p><img src="/icons/acceso.png" width={70} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-netbox') && (
                        <div className="col">
                            <a href="https://inventory.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Inventory</div>
                                        <p><img src="/icons/stock.png" width={70} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-ara') && (
                        <div className="col">
                            <a href="https://ara.idtolu.net" target={"_blank"} className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Ara Store</div>
                                        <p><img src="/icons/logo-ara.svg" width={70} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                    {keycloak.hasResourceRole('unified-ilesa') && (
                        <div className="col">
                            <a href="https://ilesa.idtolu.net" target={"_blank"} rel="noreferrer" className="card-hover text-decoration-none text-muted">
                                <div className="card text-center shadow">
                                    <div className="card-body">
                                        <div className="card-title text-card mb-1">Ilesa</div>
                                        <p><img src="/icons/Ilesa2.svg" width={70} height={50} alt="" /></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </Row>
            </div>
            <Footer />
        </div>
    );

}
export default Init;