import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { /**ToastContainer as ContainerToast,*/ Badge, Button, Card, Col, Container, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getHostNameServer, getHostNameWss } from "../../../services/helpers/config";
import LayoutPrivate from "../../public/layouts/layout-private";
import Footer from "../footer/footer";
import bytes from "bytes";

const Admin = () => {

    const { keycloak, initialized } = useKeycloak(); // hook for keycloak
    /**let navigate = useNavigate();*/

    const [dashboardSocket, setDashboardSocket] = useState(null);
    /**const [iotSocket, setIotSocket] = useState(null);*/
    const [sessions, setSessions] = useState([]);
    const [hypervisors, setHypervisors] = useState([]);

    const [projectCount, setProjectCount] = useState(0);
    const [bookingCount, setBookingCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [hypervisorsCount, setHypervisorsCount] = useState(0);
    const [instancesCount, setInstancesCount] = useState(0);

    /** const [show, setShow] = useState(false);
    const [text, setText] = useState('A new booking has been added!');*/

    useEffect(() => {
        console.log(initialized, dashboardSocket);
        const token = Date.now();
        // se hace una instancia de WebSocket de javascript
        const dashboard = new WebSocket(`${getHostNameWss()}/dashboard/live/${token}`);
        // carga la instancia dentro de la variable socket declarada del hook useState
        setDashboardSocket(dashboard);
        // evento que se genera al iniciar la conexxion satisfactoriamente
        dashboard.onopen = () => {
            dashboard.send(keycloak.token);
        }
        // evento que captura el mensaje recibido del servidor
        dashboard.onmessage = (message) => {
            let data = JSON.parse(message.data);
            if (data !== '') {
                setProjectCount(data.projectCount);
                setSessions(data.sessions);
                setBookingCount(data.bookingCount);
                setUserCount(data.userCount);
                setHypervisors(data.hypervisors);
                let countIntances = 0;
                let i = 0;
                while (i < data.hypervisors.length) {
                    countIntances = parseInt(countIntances + parseInt(data.hypervisors[i].running_vms));
                    i++;
                }
                setInstancesCount(countIntances);
                setHypervisorsCount(data.hypervisors.length);
            }
            data = null;
        }
        // evento que captura el error
        dashboard.onerror = () => {
            console.log('Error Server')
        }
        return () => dashboard.close();
    }, [setDashboardSocket]);

    const logoutAll = async () => {
        const sessionsAll = toast.loading("Deleting all sessions...");
        await axios.post(`${getHostNameServer()}/logout-all`, {}, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            if (res.data.length > 0) {
                console.log(res.data);
                toast.update(sessionsAll, { render: `Data uploaded successfully`, type: "success", autoClose: true, closeOnClick: true, isLoading: false });
            } else {
                toast.update(sessionsAll, { render: `No data to display`, type: "info", closeOnClick: true, isLoading: false });
            }
        }).catch(err => {
            toast.update(sessionsAll, { render: `We are having problems loading the sessions: ${err.response.statusText}`, type: "error", autoClose: true, closeOnClick: true, isLoading: false });
        }).finally(() => {
        });
    }

    const fromMb = (valueMb) => {
        return bytes(valueMb * 1024 * 1024);
    }

    const fromGb = (valueMb) => {
        return bytes(valueMb * 1024 * 1024 * 1024);
    }

    return ( /** render component */
        <>
            <LayoutPrivate />
            <ToastContainer />
            <Container>
                <div className="mb-4 h2 text-title">Welcome back!</div>
                <Row xs={1} md={5} className="g-4 mb-5">
                    <Col>
                        <Card className="shadow-lg">
                            <Card.Body>
                                <Card.Title className="text-muted text-title">Users</Card.Title>
                                <div className="card-text mb-3">
                                    <div className="text-weight-bold h2 d-inline text-title">{userCount}</div>
                                    <img src="/icons/users.png" style={{ width: "20%" }} alt="" className="img-fluid float-end d-inline" />
                                </div>
                                <Link to={"/users"} className="btn btn-primary btn-sm p-text">Go it</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow-lg">
                            <Card.Body>
                                <Card.Title className="text-muted text-title">Bookings</Card.Title>
                                <div className="card-text mb-3">
                                    <div className="text-weight-bold h2 d-inline text-title">{bookingCount}</div>
                                    <img src="/icons/bookings.png" style={{ width: "20%" }} alt="" className="img-fluid float-end" />
                                </div>
                                <Link to={"/bookings"} className="btn btn-primary btn-sm p-text">Go it</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow-lg">
                            <Card.Body>
                                <Card.Title className="text-muted text-title">Projects</Card.Title>
                                <div className="card-text mb-3">
                                    <div className="text-weight-bold h2 d-inline text-title">{projectCount}</div>
                                    <img src="/icons/project.png" style={{ width: "20%" }} alt="" className="img-fluid float-end" />
                                </div>
                                <Button variant="primary" size="sm" className="p-text" disabled>Go it</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow-lg">
                            <Card.Body>
                                <Card.Title className="text-muted text-title">Cloud Nodes</Card.Title>
                                <div className="card-text mb-3">
                                    <div className="text-weight-bold h2 d-inline text-title">{hypervisorsCount}</div>
                                    <img src="/icons/servers.png" style={{ width: "20%" }} alt="" className="img-fluid float-end" />
                                </div>
                                <Button variant="primary" size="sm" className="p-text" disabled>Go it</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="shadow-lg">
                            <Card.Body>
                                <Card.Title className="text-muted text-title">Cloud Instances</Card.Title>
                                <div className="card-text mb-3">
                                    <div className="text-weight-bold h2 d-inline text-title">{instancesCount}</div>
                                    <img src="/icons/instances.png" style={{ width: "20%" }} alt="" className="img-fluid float-end" />
                                </div>
                                <Button variant="primary" size="sm" className="p-text" disabled>Go it</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="table-responsive">
                            <div className="h2 text-title">Cloud</div>
                            <table className="table table-sm table-striped table-hover">
                                <thead className="text-title">
                                    <tr className="text-muted">
                                        <th>Hostname</th>
                                        <th>Type</th>
                                        <th>VCPUs (total)</th>
                                        <th>VCPUs (used)</th>
                                        <th>RAM (total)</th>
                                        <th>RAM (used)</th>
                                        <th>Local Storage (total)</th>
                                        <th>Local Storage (used)</th>
                                        <th>Instances</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="p-text">
                                    {hypervisors.map((hypervisor, _index, _array) => {
                                        return (
                                            <tr key={hypervisor.id}>
                                                <td>{hypervisor.hypervisor_hostname}</td>
                                                <td>{hypervisor.hypervisor_type}</td>
                                                <td>{hypervisor.vcpus}</td>
                                                <td>{hypervisor.vcpus_used}</td>
                                                <td>{fromMb(hypervisor.memory_mb)}</td>
                                                <td>{fromMb(hypervisor.memory_mb_used)}</td>
                                                <td>{fromGb(hypervisor.local_gb)}</td>
                                                <td>{fromGb(hypervisor.local_gb_used)}</td>
                                                <td>{hypervisor.running_vms}</td>
                                                <td>{hypervisor.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                {/*<Row className="mb-3">
                    <Col>
                        <div className="table-responsive">
                            <div className="h2">Iot</div>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className="text-muted">
                                        <th>Hostname</th>
                                        <th>Type</th>
                                        <th>VCPUs (total)</th>
                                        <th>VCPUs (used)</th>
                                        <th>RAM (total)</th>
                                        <th>RAM (used)</th>
                                        <th>Local Storage (total)</th>
                                        <th>Local Storage (used)</th>
                                        <th>Instances</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hypervisors.map((hypervisor, index, array) => {
                                        return (
                                            <tr key={index}>
                                                <td>{hypervisor.hypervisor_hostname}</td>
                                                <td>{hypervisor.hypervisor_type}</td>
                                                <td>{hypervisor.vcpus}</td>
                                                <td>{hypervisor.vcpus_used}</td>
                                                <td>{Number(hypervisor.memory_mb).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.memory_mb_used).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.local_gb).toFixed(1)} GB</td>
                                                <td>{hypervisor.local_gb_used} GB</td>
                                                <td>{hypervisor.running_vms}</td>
                                                <td>{hypervisor.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="table-responsive">
                            <div className="h2">GitLab</div>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className="text-muted">
                                        <th>Hostname</th>
                                        <th>Type</th>
                                        <th>VCPUs (total)</th>
                                        <th>VCPUs (used)</th>
                                        <th>RAM (total)</th>
                                        <th>RAM (used)</th>
                                        <th>Local Storage (total)</th>
                                        <th>Local Storage (used)</th>
                                        <th>Instances</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hypervisors.map((hypervisor, index, array) => {
                                        return (
                                            <tr key={index}>
                                                <td>{hypervisor.hypervisor_hostname}</td>
                                                <td>{hypervisor.hypervisor_type}</td>
                                                <td>{hypervisor.vcpus}</td>
                                                <td>{hypervisor.vcpus_used}</td>
                                                <td>{Number(hypervisor.memory_mb).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.memory_mb_used).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.local_gb).toFixed(1)} GB</td>
                                                <td>{hypervisor.local_gb_used} GB</td>
                                                <td>{hypervisor.running_vms}</td>
                                                <td>{hypervisor.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="table-responsive">
                            <div className="h2">MAAS</div>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className="text-muted">
                                        <th>Hostname</th>
                                        <th>Type</th>
                                        <th>VCPUs (total)</th>
                                        <th>VCPUs (used)</th>
                                        <th>RAM (total)</th>
                                        <th>RAM (used)</th>
                                        <th>Local Storage (total)</th>
                                        <th>Local Storage (used)</th>
                                        <th>Instances</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hypervisors.map((hypervisor, index, array) => {
                                        return (
                                            <tr key={index}>
                                                <td>{hypervisor.hypervisor_hostname}</td>
                                                <td>{hypervisor.hypervisor_type}</td>
                                                <td>{hypervisor.vcpus}</td>
                                                <td>{hypervisor.vcpus_used}</td>
                                                <td>{Number(hypervisor.memory_mb).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.memory_mb_used).toFixed(1)} MB</td>
                                                <td>{Number(hypervisor.local_gb).toFixed(1)} GB</td>
                                                <td>{hypervisor.local_gb_used} GB</td>
                                                <td>{hypervisor.running_vms}</td>
                                                <td>{hypervisor.status}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>*/}
                <Row xs={1} md={4}>
                    <Col>
                        <ListGroup as="ol" className="shadow-lg">
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="h6 text-title">Last sessions <sub className="text-muted">Just Now</sub></div>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">All sessions will be closed including this current session</Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button variant="secondary" size="sm" onClick={logoutAll}>Close all sessions</Button>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </ListGroup.Item>
                            {sessions.map((session, index, array) => {
                                return (<ListGroup.Item key={session.id} as="li" className="d-flex justify-content-between align-items-start" >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{session.clientId}</div>
                                        <div className="text-muted">Today</div>
                                    </div>
                                    <Badge bg="primary" pill>{session.active}</Badge>
                                </ListGroup.Item>)
                            })}
                        </ListGroup>
                    </Col>
                    {/** <Col>
                        <iframe src="https://grafana.idtolu.net/d-solo/ONHDelLnz/wifi-idtolu?orgId=2&refresh=5s&from=1681127184884&to=1681148784884&panelId=52" width="450" height="200" frameborder="0"></iframe>
                    </Col>
                    <Col>
                        <iframe src="https://grafana.idtolu.net/d-solo/ONHDelLnz/wifi-idtolu?orgId=2&refresh=5s&from=1681126383489&to=1681147983489&panelId=4" width="450" height="200" frameborder="0"></iframe>
                    </Col> */}
                </Row>
            </Container>
            {/** <ContainerToast position="bottom-end">
                <Toast className="mb-3 me-3" onClose={() => setShow(false)} show={show} delay={60000} autohide>
                    <Toast.Header>
                        <img src="/icons/others.png" width={15} className="rounded me-2" alt="" />
                        <strong className="me-auto">ID:TOLU</strong>
                        <small>Just Now</small>
                    </Toast.Header>
                    <Toast.Body>{text}</Toast.Body>
                </Toast>
            </ContainerToast> */}
            <Footer />
        </>
    );
}
export default Admin;