import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const LayoutPrivate = () => {

    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        console.log(initialized);
    }, []);

    return ( /** load or renderer component */
        <>
            <Navbar collapseOnSelect expand="lg" bg="light" className="shadow mb-5" variant="light">
                <Container fluid>
                    <Navbar.Brand href={"/"}><img src="/icons/header_logo.png" alt="ID:TOLU" width="100%" height="40" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {!!keycloak.authenticated && (
                            <>
                                <Nav className="me-auto">
                                    {/*<Link to={"/"} className="nav-link">Home</Link>*/}
                                    <Link to={"/dashboard"} className="nav-link">Dashboard</Link>
                                    {keycloak.hasRealmRole("super-admin") && (
                                        <>
                                            <Link to={"/devices"} className="nav-link">Devices</Link>
                                            <Link to={"/users"} className="nav-link">Users</Link>
                                            {/*<Link to={"/vms"} className="nav-link">Vms</Link>*/}
                                        </>
                                    )}
                                    <>
                                        <Link to={"/bookings"} className="nav-link">Bookings</Link>
                                        <Link to={"/projects"} className="nav-link">Projects</Link>
                                        <Link to={"/run"} className="nav-link">run</Link>
                                    </>
                                </Nav>
                                <Nav>
                                    {/*<Link to={"/idshell"} className="nav-link"><img src="/icons/terminal.svg" className="float-end" /></Link>*/}
                                    {/*<Link to={"#"} onClick={showShell} className="nav-link" title="Open terminal"><img src="/icons/terminal.svg" className="float-end" /></Link>*/}
                                    {/*<Button variant="light" className="nav-link">
                                        Notifications <Badge pill bg="danger">9</Badge>
                                        <span className="visually-hidden">unread messages</span>
                                    </Button>*/}
                                    <NavDropdown title={`${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`} id="collasible-nav-dropdown">
                                        <NavDropdown.Item target="_blank" href={`${keycloak.createAccountUrl()}`}>Profile</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}` })}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </>
                        )}
                        {!keycloak.authenticated && (
                            <>
                                <Nav className="me-auto">
                                    <Link to={"/"} className="nav-link">Home</Link>
                                    <Link to={"/"} className="nav-link">Docs</Link>
                                    <Link to={"/"} className="nav-link">Features</Link>
                                </Nav>
                                <Nav id="collasible-nav-dropdown">
                                    <Nav.Link onClick={() => keycloak.login()}>Login</Nav.Link>
                                    <Nav.Link onClick={() => keycloak.register()}>Register</Nav.Link>
                                </Nav>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default LayoutPrivate;