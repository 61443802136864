import React, { useEffect, useRef, useState } from "react";
import { Network } from "vis-network";
import { DataSet } from "vis-data";
import axios from "axios";
import 'vis-network/styles/vis-network.min.css';
import './style.css';
import { useKeycloak } from "@react-keycloak/web";
import { getHostNameServer } from "../../../services/helpers/config";
import { Button, Toast, ToastContainer } from "react-bootstrap";
/**import LayoutPublic from "../../public/layouts/layout-public";*/
import LayoutPrivate from "../../public/layouts/layout-private";

const CreateProject = () => {

    const clientHeight = document.documentElement.clientHeight - 70;
    /**const clientWidth = document.documentElement.clientWidth - 270;
    const [dataEvents, setDataEvents] = useState({ name: 'Project' });*/

    let network = null;
    let nodeComponent = null;

    const { keycloak, initialized } = useKeycloak();
    const container = useRef();
    const [toast, setToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nodesTemp, setNodesTemp] = useState([]);
    const [edgesTemp, setEdgesTemp] = useState([]);

    let options = {
        locale: 'en', // language to reading
        locales: {
            en: {
                edit: 'Edit',
                del: 'Delete selected',
                back: 'Back',
                addNode: 'Add device',
                addEdge: 'Add link',
                editNode: 'Edit device',
                editEdge: 'Edit link',
                addDescription: 'Click in an empty space to place a new node.',
                edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
                editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
                createEdgeError: 'Cannot link edges to a cluster.',
                deleteClusterError: 'Clusters cannot be deleted.',
                editClusterError: 'Clusters cannot be edited.'
            }
        },
        configure: {
            enabled: false, // hability interaction graphic panel
            filter: 'nodes,edges',
            showButton: true
        },
        nodes: {
            borderWidth: 1,
            borderWidthSelected: 2,
            //brokenImage: undefined,
            chosen: true,
            color: {
                border: '#2B7CE9',
                background: '#97C2FC',
                highlight: {
                    border: '#2B7CE9',
                    background: '#D2E5FF'
                },
                hover: {
                    border: '#2B7CE9',
                    background: '#D2E5FF'
                }
            },
            opacity: 1,
            fixed: {
                x: false, // allow move nodes to horizontal
                y: false // allow move nodes to vertical
            },
            font: { // propiedades de texto del nodo
                color: '#343434',
                size: 10, // px
                face: 'arial',
                background: 'none',
                strokeWidth: 0, // px
                strokeColor: '#ffffff',
                align: 'center',
                multi: false,
                vadjust: 0,
                bold: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold'
                },
                ital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'italic',
                },
                boldital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold italic'
                },
                mono: {
                    color: '#343434',
                    size: 15, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                }
            },
            group: undefined,
            heightConstraint: true,
            hidden: false,
            /*icon: {
                face: 'FontAwesome',
                code: undefined,
                weight: undefined,
                size: 50,  //50,
                color: '#2B7CE9'
            },
            image: undefined,*/
            imagePadding: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0
            },
            label: undefined,
            labelHighlightBold: true,
            level: undefined,
            mass: 2,
            physics: true,
            scaling: {
                min: 10,
                max: 30,
                label: {
                    enabled: false,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                },
                customScalingFunction: function (min, max, total, value) {
                    if (max === min) {
                        return 0.5;
                    }
                    else {
                        let scale = 1 / (max - min);
                        return Math.max(0, (value - min) * scale);
                    }
                }
            },
            shadow: {
                enabled: false,
                color: 'rgba(0,0,0,0.5)',
                size: 10,
                x: 5,
                y: 5
            },
            shape: 'ellipse',
            shapeProperties: {
                borderDashes: false, // only for borders
                borderRadius: 6,     // only for box shape
                interpolation: true,  // only for image and circularImage shapes
                useImageSize: false,  // only for image and circularImage shapes
                useBorderWithImage: false,  // only for image shape
                coordinateOrigin: 'center'  // only for image and circularImage shapes
            },
            size: 10,
            title: undefined,
            value: undefined,
            widthConstraint: true,
            //x: undefined,
            //y: undefined
        },
        edges: {
            arrows: { // Direction arrows
                to: {
                    enabled: true,
                    //imageHeight: undefined,
                    //imageWidth: undefined,
                    scaleFactor: 1,
                    //src: undefined,
                    type: "arrow"
                },
                middle: {
                    enabled: true,
                    imageHeight: 10,
                    imageWidth: 10,
                    scaleFactor: 1,
                    src: "https://visjs.org/images/visjs_logo.png",
                    type: "image"
                },
                from: {
                    enabled: true,
                    //imageHeight: undefined,
                    //imageWidth: undefined,
                    scaleFactor: 1,
                    //src: undefined,
                    type: "arrow"
                }
            },
            endPointOffset: {
                from: 0,
                to: 0
            },
            arrowStrikethrough: true,
            chosen: true,
            color: {
                color: '#848484',
                highlight: '#848484',
                hover: '#848484',
                inherit: 'from',
                opacity: 1.0
            },
            dashes: true, // arrow line/intermitent
            font: {
                color: '#343434',
                size: 14, // px
                face: 'arial',
                background: 'none',
                strokeWidth: 2, // px
                strokeColor: '#ffffff',
                align: 'horizontal',
                multi: true,
                vadjust: 0,
                bold: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold'
                },
                ital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'italic',
                },
                boldital: {
                    color: '#343434',
                    size: 14, // px
                    face: 'arial',
                    vadjust: 0,
                    mod: 'bold italic'
                },
                mono: {
                    color: '#343434',
                    size: 15, // px
                    face: 'courier new',
                    vadjust: 2,
                    mod: ''
                }
            },
            hidden: false, // hidden/show the arrows
            hoverWidth: 1.5,
            label: undefined,
            labelHighlightBold: true,
            length: undefined,
            physics: true,
            scaling: {
                min: 1,
                max: 15,
                label: {
                    enabled: true,
                    min: 14,
                    max: 30,
                    maxVisible: 30,
                    drawThreshold: 5
                },
                customScalingFunction: function (min, max, total, value) {
                    if (max === min) {
                        return 0.5;
                    }
                    else {
                        let scale = 1 / (max - min);
                        return Math.max(0, (value - min) * scale);
                    }
                }
            },
            selectionWidth: 1,
            selfReference: {
                size: 20,
                angle: Math.PI / 4,
                renderBehindTheNode: true
            },
            shadow: {
                enabled: false,
                color: 'rgba(0,0,0,0.5)',
                size: 10,
                x: 5,
                y: 5
            },
            smooth: {
                enabled: false,
                type: "dynamic",
                roundness: 0.5
            },
            title: undefined,
            value: undefined,
            width: 0.8, // width the links
            widthConstraint: false
        },
        layout: {
            randomSeed: undefined,
            improvedLayout: true,
            clusterThreshold: 150,
            hierarchical: {
                enabled: false,
                levelSeparation: 150,
                nodeSpacing: 100,
                treeSpacing: 200,
                blockShifting: true,
                edgeMinimization: true,
                parentCentralization: true,
                direction: 'UD',        // UD, DU, LR, RL
                sortMethod: 'hubsize',  // hubsize, directed
                shakeTowards: 'leaves'  // roots, leaves
            }
        },
        interaction: { // Properties to interaction with nodes
            dragNodes: true,
            dragView: true,
            hideEdgesOnDrag: false,
            hideEdgesOnZoom: false,
            hideNodesOnDrag: false,
            hover: true,
            hoverConnectedEdges: true,
            keyboard: {
                enabled: true,
                speed: { x: 10, y: 10, zoom: 0.02 },
                bindToWindow: true,
                autoFocus: true,
            },
            multiselect: false,
            navigationButtons: true,
            selectable: true,
            selectConnectedEdges: true,
            tooltipDelay: 300,
            zoomSpeed: 1,
            zoomView: true
        },
        manipulation: {
            enabled: true,
            initiallyActive: true,
            addNode: (dataNode, callback) => {
                dataNode.label = nodeComponent.label;
                dataNode.title = nodeComponent.title;
                dataNode.image = nodeComponent.image;
                dataNode.shape = nodeComponent.shape;
                addNodeLocal(dataNode);
                callback(dataNode);
            },
            editNode: (dataNode, callback) => {
                callback(dataNode);
            },
            deleteNode: (dataNode, callback) => {
                removeNodeLocal(dataNode);
                callback(dataNode);
            },
            addEdge: (dataEdge, callback) => {
                /**dataEdge.id = dataEdge.id;
                dataEdge.from = dataEdge.from;
                dataEdge.to = dataEdge.to;*/
                addEdgeLocal(dataEdge);
                callback(dataEdge);
            },
            editEdge: true,
            deleteEdge: (dataEdge, callback) => {
                removeEdgeLocal(dataEdge);
                callback(dataEdge);
            },
            controlNodeStyle: {
                // all node options are valid.
                shape: 'dot',
                size: 6,
                color: {
                    background: '#ff0000',
                    border: '#3c3c3c',
                    highlight: {
                        background: '#07f968',
                        border: '#3c3c3c'
                    }
                },
                borderWidth: 2,
                borderWidthSelected: 2
            }
        },
        physics: {
            enabled: false, // enable/disable animation of nodes and edges
            barnesHut: {
                theta: 0.5,
                gravitationalConstant: -2000,
                centralGravity: 0.3,
                springLength: 95,
                springConstant: 0.04,
                damping: 0.09,
                avoidOverlap: 0
            },
            forceAtlas2Based: {
                theta: 0.5,
                gravitationalConstant: -50,
                centralGravity: 0.01,
                springConstant: 0.08,
                springLength: 100,
                damping: 0.4,
                avoidOverlap: 0
            },
            repulsion: {
                centralGravity: 0.2,
                springLength: 200,
                springConstant: 0.05,
                nodeDistance: 100,
                damping: 0.09
            },
            hierarchicalRepulsion: {
                centralGravity: 0.0,
                springLength: 100,
                springConstant: 0.01,
                nodeDistance: 120,
                damping: 0.09,
                avoidOverlap: 0
            },
            maxVelocity: 50,
            minVelocity: 0.1,
            solver: 'barnesHut',
            stabilization: {
                enabled: true,
                iterations: 1000,
                updateInterval: 100,
                onlyDynamicEdges: false,
                fit: true
            },
            timestep: 0.5,
            adaptiveTimestep: true,
            wind: { x: 0, y: 0 }
        }
    };

    let nodes = new DataSet(nodesTemp);
    let edges = new DataSet(edgesTemp);

    useEffect(() => {
        console.log(initialized);
        network = container.current && new Network(container.current, { nodes, edges }, options);
        // Use `network` here to configure events, etc
        network.on("stabilized", (params) => {
            console.log('estabilizado');
        });
        network.on("dragEnd", (params) => {
            alert("gragged");
            /**updateNodeLocal('Dragged', params);*/
        });
        network.on("oncontext", (event) => {
            alert("Contextmenu");
            /**console.log(event);*/
        });
        /**network.on("showPopup", (event) => {
            alert("Popup");
            //console.log(event);
        });
        network.on("hoverNode", (event) => {
            alert("Hover node");
        });
        network.on("click", (params) => {
            alert("Clicked");
            //updateNodeLocal(params);
        });*/
        network.on("doubleClick", (params) => {
            console.log(params);
            alert("Here is the configuration panel for this node.");
            /**updateNodeLocal(params);*/
        });
        setInterval(() => {
            saveLocal();
        }, 900);
        
    }, [container, nodes, edges]);

    /**const updateNodeLocal = (node) => {
        const composeStored = JSON.parse(localStorage.getItem('store_data_local'));
        for (let i = 0; i < network.body.data.nodes.get().length; i++) {
            if (network.body.data.nodes.get()[i].id == node.nodes[0]) {
                composeStored.nodes[i].x = node.pointer.canvas.x;
                composeStored.nodes[i].y = node.pointer.canvas.y;
                updateLocal(composeStored);
            }
        }
    }*/

    const addNodeLocal = (node) => {
        const composeStored = JSON.parse(localStorage.getItem('store_data_local'));
        composeStored.nodes.push(node);
        updateLocal(composeStored);
    }

    const removeNodeLocal = (node) => {
        const composeStored = JSON.parse(localStorage.getItem('store_data_local'));
        composeStored.nodes.map((element, index, array) => {
            if (element.id === node.nodes[0]) {
                array.splice(index, 1);
            }
        });
        updateLocal(composeStored);
    }

    const addEdgeLocal = (edge) => {
        const local_store_data = JSON.parse(localStorage.getItem('store_data_local'));
        local_store_data.edges.push(edge);
        updateLocal(local_store_data);
        /**localStorage.setItem('store_data_local', JSON.stringify(local_store_data));*/
        console.log(edge);
    }

    const removeEdgeLocal = (edge) => {
        const local_store_data = JSON.parse(localStorage.getItem('store_data_local'));
        local_store_data.edges.map((element, index, array) => {
            if (element.id === edge.edges[0]) {
                array.splice(index, 1);
            }
        });
        updateLocal(local_store_data);
    }

    const updateLocal = (dataLocal) => {
        localStorage.removeItem("store_data_local");
        localStorage.setItem('store_data_local', JSON.stringify(dataLocal));
        /**const local_store_data = JSON.parse(localStorage.getItem('store_data_local'));
        console.log(local_store_data);*/
    }

    /* Save local data network */
    const saveLocal = () => {
        const compose = {
            nodes: network.body.data.nodes.get(),
            edges: network.body.data.edges.get()
        };
        /**localStorage.removeItem("store_data_local");*/
        localStorage.setItem('store_data_local', JSON.stringify(compose));
        /**const local_store_data = JSON.parse(localStorage.getItem('store_data_local'));
        options.physics.enabled = false;
        network.setOptions(options);*/
    }

    const onSave = async () => {
        setIsLoading(true);
        setToast(true);
        const store_data_local = JSON.parse(localStorage.getItem('store_data_local'));
        const data = {
            token_id: keycloak.idTokenParsed.sub,
            email: keycloak.idTokenParsed.email,
            name: Date.now(),
            nodes: store_data_local.nodes,
            edges: store_data_local.edges
        }

        /* set Project */
        await axios.post(`${getHostNameServer()}/projects`, data, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then((response) => {
            setNodesTemp(response.data.nodes);
            setEdgesTemp(response.data.edges);
            saveLocal();
            setIsLoading(false);
            console.log('saved');
        }).catch((error) => {
            console.log(error);
        });
    }

    const getDevice = (device) => {
        const urlRequest = "/data.json";
        const request = new XMLHttpRequest();
        request.open('GET', urlRequest);
        request.responseType = 'json';
        request.send();
        request.onload = function () {
            let i = 0;
            // nodes
            while (i < request.response.nodes.length) {
                if (request.response.nodes[i].id === device) {
                    nodeComponent = request.response.nodes[i];
                    /**setTempNode(nodeFound);*/
                }
                i++;
            }
        }
    }

    return (
        <>
        <LayoutPrivate/>
            <div className="d-flex" id="content-wrapper">
                <div className="w-100 shadow">
                    <div id="content" className="w-100">
                        <section className="py-1">
                            <div className="container-fluid">
                            <div className="text-end mb-1">
                            <Button variant="primary" size="sm" onClick={onSave}>Save all</Button>
                            </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <ul className="list-unstyled ps-0 bg-light" id="menu-sidebar">
                                            <li className="mb-1">
                                                <button className="btn btn-toggle align-items-center rounded collapsed text-size" data-bs-toggle="collapse"
                                                    data-bs-target="#switches-collapse" aria-expanded="true">
                                                    Switches
                                                </button>
                                                <div className="collapse show" id="switches-collapse">
                                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(7)}><img className="me-1" src="/uploads/devices/Switch193024G.png" /> ARUBA INSTANT 1930 24G/4SFP+</a></li>
                                                        {/*<li><a href="#" className="link-dark rounded">Switch 2</a></li>*/}
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="mb-1">
                                                <button className="btn btn-toggle align-items-center rounded collapsed text-size" data-bs-toggle="collapse"
                                                    data-bs-target="#servers-collapse" aria-expanded="true">
                                                    Machines
                                                </button>
                                                <div className="collapse show" id="servers-collapse">
                                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(1)}><img className="me-1" src="/uploads/devices/usrpB210.png" /> Usrp B210 (3)</a></li>
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(4)}><img className="me-1" src="/uploads/devices/usrpB200.png" /> Usrp B200 (3)</a></li>
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(3)}><img className="me-1" src="/uploads/devices/usrpN210.png" /> Usrp N210 (2)</a></li>
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(2)}><img className="me-1" src="/uploads/devices/usrpN310.png" /> Usrp N310 (2)</a></li>
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(5)}><img className="me-1" src="/uploads/devices/TeltonikaRUT955.png" /> Teltonika RUT955</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="mb-1">
                                                <button className="btn btn-toggle align-items-center rounded collapsed text-size" data-bs-toggle="collapse"
                                                    data-bs-target="#hosts-collapse" aria-expanded="true">
                                                    Hosts
                                                </button>
                                                <div className="collapse show" id="hosts-collapse">
                                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                        <li><a href="#" className="link-dark rounded text-size-sm" onClick={() => getDevice(8)}><img className="me-1" src="/uploads/devices/Dellxps8940.png" /> DELL XPS 8940 (2)</a></li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-10">
                                        <div id="mynetwork" ref={container} style={{ height: clientHeight }}></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <ToastContainer className="p-3" position="bottom-end">
                        <Toast onClose={() => setToast(false)} show={toast} animation="true" autohide>
                            <Toast.Header>
                                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                <strong className="me-auto">{(isLoading) ? 'Please wait...' : 'Done'}</strong>
                                <small>Just now</small>
                            </Toast.Header>
                            <Toast.Body>{(isLoading) ? 'Sending...' : 'Project send successfully'}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </div>
            </div>
        </>
    );

}
export default CreateProject;