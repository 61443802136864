import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { getHostNameServer } from "../../../services/helpers/config";
import LayoutPrivate from "../../public/layouts/layout-private";
import axios from "axios";

const Devices = () => {

    ///const [socket, setSocket] = useState(null);

    const { keycloak, initialized } = useKeycloak();

    const [devices, setDevices] = useState([]);
    ///const [device, setDevice] = useState({});

    /**useEffect(() => {

        const token = Date.now();

        // se hace una instancia de WebSocket de javascript
        const newSocket = new WebSocket(`ws://localhost:9000/realtime/admin/${token}`);

        // carga la instancia dentro de la variable socket declarada del hook useState
        setSocket(newSocket);

        // evento que se genera al iniciar la conexxion satisfactoriamente
        newSocket.onopen = () => {
            newSocket.send(token);
        }

        // evento que captura el mensaje recibido del servidor
        newSocket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            //console.log(data.results);
            if(data.results.length > 0) {
                setDevices(data.results);
            }                
        }

        // evento que captura el error
        newSocket.onerror = () => {
            console.log('Error Server')
        }

        return () => newSocket.close();
    }, [setSocket]);*/

    useEffect(() => {
        console.log(initialized);
        getDevices();
    }, []);

    const getDevices = async () => { // obtain all devices registered in the server
        await axios.get(`${getHostNameServer()}/devices`, { headers: { 'Authorization': `Bearer ${keycloak.token}` } })
        .then(result => {
            ///console.log(result.data.results);
            setDevices(result.data.results);
        }).catch(err => {
            console.log(err);
        });
    }

    const setStatus = async (event, id) => { /// obtain all devices registered in the server
        await axios.put(`${getHostNameServer()}/devices/${id}`, { status: event.target.value }, { headers: { 'Authorization': `Bearer ${keycloak.token}` } }).then(res => {
            /**setDevice(res.data);
            console.log(res.data);*/
            toast.success('Status updated', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            ///toast.dismiss();
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            getDevices();
        });
    }

    return (
        <>
            <LayoutPrivate/>
            <Container>
                <h5 className="text-dark mb-3 text-center">TestBed appliance registration</h5>
                <Row className="mb-3">
                    <Col className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-sm table-striped table-hover" width={100}>
                                <thead>
                                    <tr>
                                        <th>Label</th>
                                        <th>Name</th>
                                        <th>IP Address</th>
                                        {/*<th>Image/icon</th>*/}
                                        <th>Location</th>
                                        <th>Description</th>
                                        {/*<th>Status</th>*/}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices.length > 0 ? devices.map((device, j) => {
                                        return (
                                            <tr key={device.id}>
                                                <td>{device.name}</td>
                                                <td>{device.device_type.display}</td>
                                                <td>{device.primary_ip ? device.primary_ip.address : '-'}</td>
                                                {/*<td><img src={`https://assets.ubuntu.com/v1/cb22ba5d-favicon-16x16.png`} width={25} /></td>*/}
                                                <td>{device.location.name}</td>
                                                <td>{device.comments}</td>
                                                {/*<td>{device.status.label}</td>*/}
                                                <td>
                                                    <select className="form-control form-control-sm" value={device.status.value} onChange={(e) => setStatus(e, device.id)}>
                                                        <option value="active">Active</option>
                                                        <option value="offline">Offline</option>
                                                    </select>{' '}
                                                    {/*<button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => getDevice(device)}>ver</button>*/}
                                                </td>
                                            </tr>)
                                    }) : <tr>
                                        <td colSpan={7}><div className="text-center mt-4"> <h2> Not display data</h2></div></td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </>
    );
}
export default Devices;