import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Layout = () => {

    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        console.log(initialized)
    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" bg="light" className="shadow mb-5" variant="light">
            <Container fluid>
                <Navbar.Brand href={"/"}><img src="/icons/header_logo.png" alt="ID:TOLU" width="100%" height="40" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {!!keycloak.authenticated && (
                        <>
                            <Nav className="me-auto">
                            </Nav>
                            <Nav>
                                {/*<Link to={"/terminal"} className="nav-link"><img src="/icons/terminal.svg" className="float-end" /></Link>*/}
                                <NavDropdown title={`${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`} id="collasible-nav-dropdown">
                                    <NavDropdown.Item target="_blank" href="http://179.1.87.35:8080/realms/idtolu/account/">Profile</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => keycloak.logout({ redirectUri: "http://localhost:3000" })}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </>
                    )}
                    {!keycloak.authenticated && (
                        <>
                            <Nav className="me-auto">
                                <Link to={"/"} className="nav-link">Home</Link>
                                <Link to={"/"} className="nav-link">Docs</Link>
                                <Link to={"/"} className="nav-link">Features</Link>
                            </Nav>
                            <Nav id="collasible-nav-dropdown">
                                <Nav.Link onClick={() => keycloak.login()}>Login</Nav.Link>
                                <Nav.Link onClick={() => keycloak.register()}>Register</Nav.Link>
                            </Nav>
                        </>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

const Error404 = () => {
    return (
        <>
        <Layout/>
            <div style={{
                height: '200px',
                lineHeight: '600px',
                textAlign: 'center'}}>
                <h1 style={{display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal'}}>PAGE NOT FOUND 404</h1>
            </div>
        </>
    );
}
export default Error404;